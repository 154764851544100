import React from 'react';
import { Page, Navbar, BlockTitle, Link, NavRight,List,ListItem,Icon } from 'framework7-react';
import { API, graphqlOperation }  from "aws-amplify";
//import ReactJson from 'react-json-view'
import _ from 'lodash';
export default class extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            pathToAssets:'https://media.carnegisapp.com/',
            project:{
                resources:{
                    items:[]
                }
            },
            projectGrouped:""
        }
    }

    async getProjects(){
        try{
            const result = await API.graphql(graphqlOperation(
                `query {
                    getProject(id:"` 
                    +  this.$f7route.params.id +
                    `") {
                    id
                    projectname
                    resources(limit:100){
                        items{
                            id
                            resourcename
                            resourcetype
                            sectionname
                            sectionorder
                            order
                        }
                        nextToken
                        }
                    }
                }`
            ))
            this.setState({project:result.data.getProject})
            //const groups = 
                _.mapValues(
                    _.sortBy(
                        _.groupBy(
                            result.data.getProject.resources.items,"sectionname"
                        ), 
                        clist => clist.map(
                            sectionname => _.omit(sectionname, 'sectionname')
                        )
                    , ['sectionorder', 'order'], ['asc', 'asc'])
                    
                )
            const groups=_.groupBy(_.sortBy(result.data.getProject.resources.items,["sectionorder", "order"], ["asc", "asc"]), "sectionorder")
            //console.log("Sections", result.data.getProject.resources, _.groupBy(result.data.getProject.resources.items, "sectionname"))
            this.setState({projectGrouped:groups}) 
            //console.log("Grouping", groups)
          }catch(err){
            console.log("Error loading project",err)
          }
    }

    componentWillMount(){
        this.getProjects();
    }

    renderSwitch(param) {
        switch(param) {
          case 'documents':
            return 'layers';
        //break;
            case "billing":
            return 'money_dollar_round'
        //break;
        case 'contract':
            return 'edit'
        //break;
            case "approval":
                return 'check_round'
        //break;
          default:
            console.log("No icon for ", param)
        }
      }

  render() {
        return (
            <Page>
                <Navbar title={this.state.project.projectname} backLink="Back" large >
                    <NavRight>
                        <Link iconIos="f7:reload" iconAurora="f7:reload" iconMd="material:compare_arrows" onClick={() => this.setState({isBottom: !this.state.isBottom})}></Link>
                    </NavRight>
                    
                    {/* <Subnavbar>
                        <Segmented raised>
                        <Button tabLink="#tab1" tabLinkActive>Project</Button>
                        <Button tabLink="#tab2">Contacts</Button>
                        </Segmented>
                    </Subnavbar> */}
                </Navbar>
                <div>
                {Object.keys(this.state.projectGrouped).map((key, i) => (
                    <div key={key}>
                        <BlockTitle>{this.state.projectGrouped[key][0].sectionname}</BlockTitle>
                        <List>
                            {this.state.projectGrouped[key].map((resource) => 
                                <ListItem key={resource.id} link={"/home/project/content/" + resource.resourcetype + '/' + resource.id + '/' + resource.resourcename + '/'} title={resource.resourcename}>
                                    <Icon slot="media" f7={this.renderSwitch(resource.resourcetype)} style={{'color':'grey'}}></Icon>
                                </ListItem>
                            )}
                        </List>
                    </div>
                ))}
                </div>
            </Page>
        )
    }
}