// eslint-disable
// this is an auto generated file. This will be overwritten

export const createClient = `mutation CreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    id
    clientname
    clientwebsite
    clientphone
    projects {
      items {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      nextToken
    }
    contacts {
      items {
        id
        contactfirstname
        contactlastname
        contactemail
        contactcellphone
        contactofficephone
        contactaddress1
        contactaddress2
        contactcity
        contactzip
        contactcountry
      }
      nextToken
    }
  }
}
`;
export const updateClient = `mutation UpdateClient($input: UpdateClientInput!) {
  updateClient(input: $input) {
    id
    clientname
    clientwebsite
    clientphone
    projects {
      items {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      nextToken
    }
    contacts {
      items {
        id
        contactfirstname
        contactlastname
        contactemail
        contactcellphone
        contactofficephone
        contactaddress1
        contactaddress2
        contactcity
        contactzip
        contactcountry
      }
      nextToken
    }
  }
}
`;
export const deleteClient = `mutation DeleteClient($input: DeleteClientInput!) {
  deleteClient(input: $input) {
    id
    clientname
    clientwebsite
    clientphone
    projects {
      items {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      nextToken
    }
    contacts {
      items {
        id
        contactfirstname
        contactlastname
        contactemail
        contactcellphone
        contactofficephone
        contactaddress1
        contactaddress2
        contactcity
        contactzip
        contactcountry
      }
      nextToken
    }
  }
}
`;
export const createProject = `mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    id
    projectname
    projectcity
    projectimage
    order
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
    resources {
      items {
        id
        resourcename
        resourcetype
        sectionname
        sectionorder
        order
        resourcelocation
        structure
        status
      }
      nextToken
    }
    structure
  }
}
`;
export const updateProject = `mutation UpdateProject($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    id
    projectname
    projectcity
    projectimage
    order
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
    resources {
      items {
        id
        resourcename
        resourcetype
        sectionname
        sectionorder
        order
        resourcelocation
        structure
        status
      }
      nextToken
    }
    structure
  }
}
`;
export const deleteProject = `mutation DeleteProject($input: DeleteProjectInput!) {
  deleteProject(input: $input) {
    id
    projectname
    projectcity
    projectimage
    order
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
    resources {
      items {
        id
        resourcename
        resourcetype
        sectionname
        sectionorder
        order
        resourcelocation
        structure
        status
      }
      nextToken
    }
    structure
  }
}
`;
export const createResources = `mutation CreateResources($input: CreateResourcesInput!) {
  createResources(input: $input) {
    id
    resourcename
    resourcetype
    sectionname
    sectionorder
    order
    resourcelocation
    structure
    status
    project {
      id
      projectname
      projectcity
      projectimage
      order
      client {
        id
        clientname
        clientwebsite
        clientphone
      }
      resources {
        nextToken
      }
      structure
    }
    content {
      items {
        id
        type
        name
        filetype
        fileextension
        size
        label
        structure
        resourceid
      }
      nextToken
    }
  }
}
`;
export const updateResources = `mutation UpdateResources($input: UpdateResourcesInput!) {
  updateResources(input: $input) {
    id
    resourcename
    resourcetype
    sectionname
    sectionorder
    order
    resourcelocation
    structure
    status
    project {
      id
      projectname
      projectcity
      projectimage
      order
      client {
        id
        clientname
        clientwebsite
        clientphone
      }
      resources {
        nextToken
      }
      structure
    }
    content {
      items {
        id
        type
        name
        filetype
        fileextension
        size
        label
        structure
        resourceid
      }
      nextToken
    }
  }
}
`;
export const deleteResources = `mutation DeleteResources($input: DeleteResourcesInput!) {
  deleteResources(input: $input) {
    id
    resourcename
    resourcetype
    sectionname
    sectionorder
    order
    resourcelocation
    structure
    status
    project {
      id
      projectname
      projectcity
      projectimage
      order
      client {
        id
        clientname
        clientwebsite
        clientphone
      }
      resources {
        nextToken
      }
      structure
    }
    content {
      items {
        id
        type
        name
        filetype
        fileextension
        size
        label
        structure
        resourceid
      }
      nextToken
    }
  }
}
`;
export const createContent = `mutation CreateContent($input: CreateContentInput!) {
  createContent(input: $input) {
    id
    type
    name
    filetype
    fileextension
    size
    label
    structure
    resourceid
    resources {
      id
      resourcename
      resourcetype
      sectionname
      sectionorder
      order
      resourcelocation
      structure
      status
      project {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const updateContent = `mutation UpdateContent($input: UpdateContentInput!) {
  updateContent(input: $input) {
    id
    type
    name
    filetype
    fileextension
    size
    label
    structure
    resourceid
    resources {
      id
      resourcename
      resourcetype
      sectionname
      sectionorder
      order
      resourcelocation
      structure
      status
      project {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const deleteContent = `mutation DeleteContent($input: DeleteContentInput!) {
  deleteContent(input: $input) {
    id
    type
    name
    filetype
    fileextension
    size
    label
    structure
    resourceid
    resources {
      id
      resourcename
      resourcetype
      sectionname
      sectionorder
      order
      resourcelocation
      structure
      status
      project {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const createContacts = `mutation CreateContacts($input: CreateContactsInput!) {
  createContacts(input: $input) {
    id
    contactfirstname
    contactlastname
    contactemail
    contactcellphone
    contactofficephone
    contactaddress1
    contactaddress2
    contactcity
    contactzip
    contactcountry
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
}
`;
export const updateContacts = `mutation UpdateContacts($input: UpdateContactsInput!) {
  updateContacts(input: $input) {
    id
    contactfirstname
    contactlastname
    contactemail
    contactcellphone
    contactofficephone
    contactaddress1
    contactaddress2
    contactcity
    contactzip
    contactcountry
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
}
`;
export const deleteContacts = `mutation DeleteContacts($input: DeleteContactsInput!) {
  deleteContacts(input: $input) {
    id
    contactfirstname
    contactlastname
    contactemail
    contactcellphone
    contactofficephone
    contactaddress1
    contactaddress2
    contactcity
    contactzip
    contactcountry
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
}
`;
