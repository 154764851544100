import React from 'react';
import {
  App,
  Panel,
  View,
  Statusbar
} from 'framework7-react';
import routes from '../routes';
//import { Authenticator, Greetings, TOTPSetup, Loading } from "aws-amplify-react/dist/Auth";
//import config from "../aws-exports";
import { CustomSignIn } from "./pages/CustomSignIn";
import Amplify, { Analytics } from 'aws-amplify';
//import * as queries from '../graphql/queries';
import { withAuthenticator,ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignUp, VerifyContact } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
import awsmobile from '../aws-exports';
Amplify.configure(awsmobile);
//Amplify.configure();
Analytics.disable();
class carnegis extends React.Component {
 
  
  render() {

  // Framework7 parameters here
  const f7params = {
    id: 'com.carnegisapp.carnegisapp', // App bundle ID
    name: 'Carnegis App', // App name
    theme: 'ios', // Automatic theme detection
    title:"Carnegis Group",
    init:true,
    // App routes
    routes,
    data:function(){
      return {
        test:true
      }
    }
  };

  

  return (


        <App params={f7params} colorTheme="red">
      {/* Statusbar */}
      <Statusbar 
        enabled="true"
        overlay="true"
        iosBackgroundColor="#000000"
        androidBackgroundColor="#000000"
      />
  
      {/* Left Panel */}
      <Panel left cover themeDark>
        <View url="/panel-left/" />
      </Panel>

      {/* Right Panel */}
      <Panel right cover themeDark>
        <View url="/panel-right/"/>
      </Panel>

      {/* Main View */}
      <View id="main-view" url="/" main className="safe-areas"/>
    </App>


    
 );
}
};


export default withAuthenticator(carnegis, true, [
  <CustomSignIn/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <SignUp/>,
  <ConfirmSignUp/>,
  <ForgotPassword/>,
  <RequireNewPassword />
], null)
