import React from 'react';
import {
	Page,
	Navbar,
	Card,
	Block,
	Popup,
	Row,
	Col,
	Toggle,
	ListButton,
	Link,
	NavRight,
	List,
    ListItem,
    ListInput,
	Fab,
	FabButtons,
	FabButton,
	Icon,
	CardContent,
	Button
} from 'framework7-react';
export default class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pathToAssets: 'https://media.carnegisapp.com/',
			test: true
		};
	}

	render() {
		return (
			<Page>
				<Navbar title={this.$f7route.params.name} backLink="Back">
					<NavRight>
						<Link
							iconIos="f7:reload"
							iconAurora="f7:reload"
							iconMd="material:compare_arrows"
							onClick={() => this.setState({ isBottom: !this.state.isBottom })}
						/>
					</NavRight>
				</Navbar>

				<Fab position="right-bottom" slot="fixed">
					<Icon ios="f7:add" aurora="f7:add" md="material:add" />
					<Icon ios="f7:close" aurora="f7:close" md="material:close" />
					<FabButtons position="top">
						<FabButton label="Receipt" ios="f7:add">
							<Icon ios="f7:arrow_down" />
						</FabButton>
						<FabButton label="Invoice" ios="f7:add">
							<Icon ios="f7:arrow_up" />
						</FabButton>
					</FabButtons>
				</Fab>

				<Block>
					<Row>
						<Col>
							<Button className="float-left" popupOpen=".popup-create-invoice">
								CREATE INVOICE
							</Button>
						</Col>
						<Col>
							<Button className="float-right">CREATE INVOICE</Button>
						</Col>
					</Row>
				</Block>

				<Card outline title={this.$f7route.params.name}>
					<CardContent>
						<center>
							<img alt="invoice" src={this.state.pathToAssets + 'static/invoice.png'} height="100" />
						</center>

						<List>
							{/*<ListItem
                        link="#"
                        title="Invoice"
                        after="#34895"
                        subtitle="$100,000"
                        text="Paid">
                        <img slot="media" alt="invoice" src={this.state.pathToAssets + 'static/invoice.png'} height="44" />
                </ListItem>*/}

							<ListItem>
								<span>Received Payment</span>
								<Toggle value={this.state.approved} onChange={this.approveSwitch} />
							</ListItem>
							<ListButton onClick={this.setApproval}>Submit</ListButton>
						</List>
					</CardContent>
				</Card>

				<Popup className="popup-create-invoice" swipeToClose>
					<Page>
						<Navbar title="Add Invoice">
							<NavRight>
								<Link popupClose>Close</Link>
							</NavRight>
						</Navbar>

						
							<List inlineLabels noHairlinesMd>
								<ListInput label="Amount" type="text" placeholder="" clearButton>
									<Icon f7="money_dollar" slot="media" />
								</ListInput>
                                <ListInput label="Due Date" type="date" placeholder="" clearButton>
									<Icon f7="calendar" slot="media" />
								</ListInput>
                                <ListInput label="Description" type="text" placeholder="" clearButton>
									<Icon f7="more_fill" slot="media" />
								</ListInput>
                                <ListButton>Submit</ListButton>
							</List>
					
					</Page>
				</Popup>
			</Page>
		);
	}
}
