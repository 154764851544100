import React from 'react';
import { Page, Navbar, Button, BlockTitle, List, ListItem } from 'framework7-react';
import { Auth } from 'aws-amplify';



function signOut(){
    console.log(this.$f7)
  Auth.signOut({ global: true })
    .then(data => window.location.reload())
    .catch(err => console.log(err));
}
/*
function getUser(){
  Auth.currentAuthenticatedUser({
    bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      return console.log(user);
    })
    .catch(err => {
      return console.log(err);
    });
}*/



export default class extends React.Component{
  constructor(props) {
    super(props);
    this.state={
        user:{}
    }
}
  componentWillMount(){
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        this.setState({user:user.attributes})
        //console.log("User", user.attributes)
      })
  }

  render(){
    return (
      <Page>
        <Navbar title="Configure" />
        <BlockTitle>User</BlockTitle>
        <div>Name: {this.state.user.given_name} {this.state.user.family_name} {this.state.user.email}</div>
        <Button onClick={signOut}>Sign Out</Button>
        <List>
          <ListItem link="/about/" title="About"></ListItem>
          <ListItem link="/form/" title="Form"></ListItem>
        </List>
        <BlockTitle>Load page in main view</BlockTitle>
        <List>
          <ListItem link="/about/" title="About" view="#main-view" panelClose></ListItem>
          <ListItem link="/form/" title="Form" view="#main-view" panelClose></ListItem>
        </List>
      </Page>
    )
  }

  
}
