import React from 'react';
import { Page, Navbar, Link, NavRight, List, ListItem, Toggle, ListInput, Icon, ListButton, Block} from 'framework7-react';
import { API, graphqlOperation}  from "aws-amplify";
import { Auth } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

export default class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            approved:0,
            resource:{
                structure:{
                    approvedon:'',
                    approvalattributes:{
                        given_name:'',
                        family_name:''
                    }
                }
            },
            user:{
                username:'',
                attributes:{}
            }
        }
        this.approveSwitch = this.approveSwitch.bind(this);
        this.setApproval = this.setApproval.bind(this);
    }
    getUser(){
        Auth.currentAuthenticatedUser({
          bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          }).then(user => {
              console.log("User", user)
            this.setState({'user': user})
          })
          .catch(err => {
            return console.log('Error retrieving user informtation', err);
          });
      }
approveSwitch(event){
    this.setState({approved: event.target.checked ? 1 : 0});    
}
setApproval(e){
    this.setState(Object.assign(this.state['resource'],{status:'complete'}))
    this.setState(Object.assign(this.state['resource'],{structure:{
        'approvedby':this.state.user.username,
        'approvalattributes':this.state.user.attributes,
        'date':new Date(),
    }}))
    console.log(this.state)
    try{
        const updateResources = API.graphql(
          graphqlOperation(
            mutations.updateResources, {
              input: {
                id:this.state.resource.id,
                status:'complete',
                structure:JSON.stringify(this.state.resource.structure)
               }
            }
          )).then(function(result){
              console.log("Received", result)
          })
          console.log(updateResources)
      }catch(err)
      {
        console.log(err)
      }
}

async getContent(){
    console.log("Getting Content", )
    try{
        const result = await API.graphql(graphqlOperation(
          `query {
            getResources(id:"` +  this.$f7route.params.id +`") {
                id
                resourcename
                structure
                status
            }
          }`))
          this.setState({resource:result.data.getResources})
            console.log('Resource',this.state.resource)
          if (!this.state.resource.status){
            console.log("Status undefined.")
          }
          
        } catch (err) {
          console.log('Error loading Resource', err)
        }
}

componentWillMount(){
    this.getContent();
    this.getUser();
}

    render() {
        return (
            <Page>
                <Navbar title={this.$f7route.params.name} backLink="Back">
                    <NavRight>
                        <Link iconIos="f7:reload" iconAurora="f7:reload" iconMd="material:compare_arrows" onClick={() => this.setState({isBottom: !this.state.isBottom})}></Link>
                    </NavRight>
                </Navbar>
                {this.state.resource.status === 'complete' ?
                    <Block inset>  
                        <center>
                          <img src='img/approved.png' alt='Approved Seal'></img>
                          <Block>
                            <p><b>Approved by: </b>{JSON.parse(this.state.resource.structure).approvalattributes.given_name + ' ' + JSON.parse(this.state.resource.structure).approvalattributes.family_name}</p>
                            <p><b>Approved on:</b>{JSON.parse(this.state.resource.structure).date}</p>
                          </Block>                  
                        </center>
                    </Block>
                    : 
                    <List>
                        <ListInput 
                            label="Full Name" 
                            type="text" 
                            placeholder="Your full name" 
                            disabled={true}
                            value={this.state.user.attributes.given_name + ' ' + this.state.user.attributes.family_name}>
                            <Icon icon="f7-pen" slot="media"/>
                        </ListInput>
                        <ListItem>
                            <span>Approve</span>
                            <Toggle value={this.state.approved} onChange={this.approveSwitch}></Toggle>
                        </ListItem>
                        {this.state.approved ? 
                        <ListButton onClick={this.setApproval}>Submit</ListButton>
                        : null }
                    </List>
                }
                
                
            </Page>
        )
    }
}