import React from 'react';
import { Page, Navbar, Icon, BlockTitle, Link, NavRight,List,ListItem,ListGroup } from 'framework7-react';

export default class extends React.Component{

  render() {
        return (
            <Page>
                <Navbar title="Project" backLink="Back">
                    <NavRight>
                        <Link iconIos="f7:reload" iconAurora="f7:reload" iconMd="material:compare_arrows" onClick={() => this.setState({isBottom: !this.state.isBottom})}></Link>
                    </NavRight>
                </Navbar>
                <BlockTitle>Proposal</BlockTitle>
                <List>
                    <ListItem title="Project Configuration Form" link="/project/form/">
                        <Icon slot="media" f7="edit"></Icon>
                    </ListItem>
                    <ListItem title="Contract" link="/project/form/">
                        <Icon slot="media" f7="edit"></Icon>
                    </ListItem>
                </List>
                <BlockTitle>Additional Space Planning</BlockTitle>
                <List>
                    <ListItem title="Planning Fee (50%)" link="/project/billing/">
                        <Icon slot="media" f7="money_dollar_round"></Icon>
                    </ListItem>
                    <ListItem title="Building Shell" link="#">
                        <Icon slot="media" f7="layers"></Icon>
                    </ListItem>
                    <ListItem title="List of Requirements" link="#">
                        <Icon slot="media" f7="document_check"></Icon>
                    </ListItem>
                    <ListItem title="Layout Designs" link="#">
                        <Icon slot="media" f7="layers"></Icon>
                    </ListItem>
                    <ListItem title="Approval" link="#">
                        <Icon slot="media" f7="check"></Icon>
                    </ListItem>
                    <ListItem title="Planning Fee (50%)" link="#">
                        <Icon slot="media" f7="money_dollar_round"></Icon>
                    </ListItem>
                </List>
                <BlockTitle>Design</BlockTitle>
                <List>
                    <ListItem title="Client Vision Report" link="#">
                        <Icon slot="media" f7="document"></Icon>
                    </ListItem>
                    <ListItem title="Architect Requirements" link="#">
                        <Icon slot="media" f7="document"></Icon>
                    </ListItem>
                    <ListItem title="Electrical Plan" link="#">
                        <Icon slot="media" f7="layers"></Icon>
                    </ListItem>
                    <ListItem title="Decorative Lighting Plan" link="#">
                        <Icon slot="media" f7="layers"></Icon>
                    </ListItem>
                    <ListItem title="Luminaire Schedule" link="#">
                        <Icon slot="media" f7="document"></Icon>
                    </ListItem>
                </List>
                <BlockTitle>Drafting & Construction Documentation</BlockTitle>
                <List>
                    <ListItem title="CAD Fees (100%)" link="#">
                        <Icon slot="media" f7="money_dollar_round"></Icon>
                    </ListItem>
                    <ListItem title="Final CAD background fully coordinated with Mechanical, Electrical and Plumbing trades" link="#">
                        <Icon slot="media" f7="layers"></Icon>
                    </ListItem>
                    <ListItem title="Final ceiling heights from floor to finished underside of ceiling" link="#">
                        <Icon slot="media" f7="layers"></Icon>
                    </ListItem>
                    <ListItem title="Elevations of the mail room" link="#">
                        <Icon slot="media" f7="layers"></Icon>
                    </ListItem>
                    <ListItem title="General lighting plan with switch and emergency light
locations" link="#">
                        <Icon slot="media" f7="layers"></Icon>
</ListItem>
                    <ListItem title="Electrical plan with our specialty outlets already coordinated" link="#">
                        <Icon slot="media" f7="layers"></Icon>
                    </ListItem>
                    <ListGroup>
                        <ListItem title="CAD Drawings" groupTitle><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Construction Plan" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Reflected ceiling plan" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Furniture Plan" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Floor Finish Plan" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Finish Plan" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Electrical plan" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Decorative Lighting Plan" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Ceiling Finish Plan" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Detail Location Plan" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Interior Elevations" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Details" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                        <ListItem title="Specifications" link="#"><Icon slot="media" f7="document"></Icon></ListItem>
                    </ListGroup>
                </List>
                <BlockTitle>Construction Administration</BlockTitle>
                <List>
                    <ListItem title="Construction Admin fee (50%)" link="#"><Icon slot="media" f7="money_dollar_round"></Icon></ListItem>
                    <ListItem title="RFIs" link="#"><Icon slot="media" f7="document"></Icon></ListItem>
                    <ListItem title="Submittals" link="#"><Icon slot="media" f7="document"></Icon></ListItem>
                    <ListItem title="Construction Admin fee (50%)" link="#"><Icon slot="media" f7="money_dollar_round"></Icon></ListItem>
                </List>
                <BlockTitle>FF&E Installation</BlockTitle>
                <List>
                    <ListItem title="Shipping and Contact information" link="#"><Icon slot="media" f7="document" /></ListItem>
                    <ListItem title="Field measurements for custom built-in furniture" link="#"><Icon slot="media" f7="layers" /></ListItem>
                    <ListItem title="Highlighted plan" link="#"><Icon slot="media" f7="layers"></Icon></ListItem>
                    <ListItem title="FF&E Fees (50%)" link="#"><Icon slot="media" f7="money_dollar_round"></Icon></ListItem>
                    <ListItem title="Scheduling of the installation" link="#"><Icon slot="media" f7="document"></Icon></ListItem>
                    <ListItem title="Walkthrough of furnished spaces" link="#"><Icon slot="media" f7="document"></Icon></ListItem>
                    <ListItem title="Inventory" link="#"><Icon slot="media" f7="document"></Icon></ListItem>
                    <ListItem title="FF&E Fees (50%)" link="#"><Icon slot="media" f7="money_dollar_round"></Icon></ListItem>
                </List>
            </Page>
        )
    }
}