// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://btgk73vbw5cpnbl2z3w5p7pjty.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fdsjt3jodndbrbt3dpxshim2nu",
    "aws_cognito_identity_pool_id": "us-west-2:ad1aa0a1-2f8d-4f3a-9202-ea092a8ce7a1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ZMugZ8lbz",
    "aws_user_pools_web_client_id": "2iblivg2ddme4es9dambeprni3",
    "oauth": {},
    "aws_mobile_analytics_app_id": "a276d2f6334444a98550cb534b6aafac",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_user_files_s3_bucket": "carnegis1-carnegis",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_content_delivery_bucket": "carnegis1-carnegis-carnegis",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d37fc44u8x84gg.cloudfront.net"
};


export default awsmobile;
