import React from 'react';
import { Page, Navbar, BlockTitle, Link, NavRight,List,ListItem } from 'framework7-react';

export default class extends React.Component{

  render() {
        return (
            <Page>
                <Navbar title="Billing" backLink="Back">
                    <NavRight>
                        <Link iconIos="f7:reload" iconAurora="f7:reload" iconMd="material:compare_arrows" onClick={() => this.setState({isBottom: !this.state.isBottom})}></Link>
                    </NavRight>
                </Navbar>
                <BlockTitle>Transactions</BlockTitle>
                <List>
                <ListItem
                    link="#"
                    title="Invoice"
                    badge=""
                    >
                </ListItem>
                <ListItem
                    link="#"
                    title="Payment"
                    badge=""
                    >
                </ListItem>
                <ListItem
                    link="#"
                    title="Receipt"
                    badge=""
                    >
                </ListItem>
            </List>
        </Page>
        )
  }
}