import React, { Component } from 'react';
import { Page, Navbar, List, ListInput, ListButton } from 'framework7-react';
import { API, graphqlOperation } from 'aws-amplify';
import { updateClient } from '../../graphql/mutations'
import { deleteClient } from '../../graphql/mutations'
//import * as queries from '../../graphql/queries';
//import { Connect } from "aws-amplify-react";

export default class DynamicRoutePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:{
                clientname:"",
                clientid:"",
                clientphone:"",
                clientwebsite:""
            },
            loading:""
        };
        this.handleChange = this.handleChange.bind(this);
      }
    
      handleChange(name, ev) {
        this.setState(Object.assign(this.state.data,{[name]:ev.target.value}));
      }

      async setUpdateForm(){
        
        const result = await API.graphql(graphqlOperation(updateClient, {input:this.state.data}))
        console.log(result)
      }

      async setDeleteClient(){
        try {
          
          const result = await API.graphql(graphqlOperation(deleteClient, {input:{id:this.$f7route.params.id}}))
          console.log(result)
        } catch(err){
            console.log(err)
        }
      }

        
     async componentWillMount() {
        try {
            const getClientField=`
                query {
                    getClient(id:"` 
                    +  this.$f7route.params.id +
                    `") {
                    id
                    clientid
                    clientname
                    clientphone
                    clientwebsite
                    }
                }`
            const result = await API.graphql(graphqlOperation(getClientField, {
                id:this.$f7route.params.id,
                
            }));
            this.setState({ data: result.data.getClient });
        } catch (err) {
            console.log("Error", err)
            this.setState({ loading: false, errors: [ err.message ] });
        }
    }
  render() {
    return (
      <Page>
          <Navbar title="Edit Client" backLink="Back" />
           <List form>
           <ListInput
                label="Client Id"
                type="text"
                placeholder="Client Code"
                value={this.state.data.clientid}
                onChange={(ev) => { this.handleChange('clientid', ev)}}
            />
            <ListInput
                label="Client Name"
                type="text"
                placeholder="The name of the client"
                value={this.state.data.clientname}
                onChange={(ev) => { this.handleChange('clientname', ev)}}
            />
            <ListInput
                label="Client Phone"
                type="text"
                placeholder="The phone number of the client"
                value={this.state.data.clientphone}
                onChange={(ev) => { this.handleChange('clientphone', ev)}}
            />
            <ListInput
                label="Client Website"
                type="text"
                placeholder="http://"
                value={this.state.data.clientwebsite}
                onChange={(ev) => { this.handleChange('clientwebsite', ev)}}
            />
            <ListButton onClick={(ev) => this.setUpdateForm()}>Update</ListButton>
            <ListButton onClick={(ev) => this.setDeleteClient()}>Delete</ListButton>
            </List>
      </Page>
    )
  }
}