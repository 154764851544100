import React from 'react';
import {
  Page,
  Navbar,
  List,
  ListItem,
  ListInput,
  Toggle,
  BlockTitle,
  ListButton
} from 'framework7-react';

export default () => (
  <Page>
    <Navbar title="Proposal Form" backLink="Back" />
    <BlockTitle>Proposal Form</BlockTitle>
    <List form>
      <ListInput
        label="Name of Project"
        type="text"
        placeholder="Project Name"
      />
      <ListInput
        label="Address Line 1"
        type="text"
        placeholder="123 Elm Street"
      />
      <ListInput
        label="Address Line 2"
        type="text"
        placeholder=""
      />
      <ListInput
        label="City"
        type="text"
        placeholder="Springfield"
      />
      <ListInput
        label="State"
        type="text"
        placeholder="Texas"
      />
      <ListInput
        label="Zip Code"
        type="text"
        placeholder="77000"
      />
      <ListInput
        label="Square footage of all common areas"
        type="text"
        placeholder=""
      />
      <ListInput
        label="Commencement Date (Estimated)"
        type="date"
        placeholder="1/1/2020" value=""
      />
      <ListInput
        label="Completion Date (Estimated)"
        type="date"
        placeholder="1/1/2021" value=""
      />
      <ListInput
        label="Number of models to be staged, description / number of
bedrooms"
        type="textarea"
        placeholder="5 models..."
        resizable
      />
      <ListItem title="Space planning services?">
        <Toggle slot="after" />
      </ListItem>
      <ListItem title="Corridor design?">
        <Toggle slot="after" />
      </ListItem>
      <ListItem title="Amenities and models installed at the same time?">
        <Toggle slot="after" />
      </ListItem>
      <ListItem title="Outdoor budget?">
        <Toggle slot="after" />
      </ListItem>
 
        <ListButton>Update</ListButton>
 
      </List>
  </Page>
);
