import React from 'react';
import {
  Page,
  Navbar,
  List,
  ListInput,
  BlockTitle,
  ListButton,
  ListItem
} from 'framework7-react';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import * as subscriptions from '../../graphql/subscriptions';
import { API, graphqlOperation }  from "aws-amplify";
import { Connect } from "aws-amplify-react";

class SettingsClients extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange(name, ev) {
    this.setState({ [name]: ev.target.value });
  }

 

  async submit() {
    const newClient = await API.graphql(
      graphqlOperation(
        mutations.createClient, {
          input: this.state
        }
      )
    );
    console.log("result", newClient)
  }

  
    render() {
      


      const ClientsView = ({ clients }) => (
          <List>
              {clients.map(client => (
                
                <ListItem 
                  key={client.clientid} 
                  title={client.clientname} 
                  id={client.id}
                  link={"/settings/clients/modify/" + client.id + "/"}>
                </ListItem>
                ))}
          </List>
      );
      return(
  <Page>
    <Navbar title="Clients" backLink="Back" />
      <BlockTitle>Clients</BlockTitle>
      
      <Connect query={graphqlOperation(queries.listClients)}
        subscription={graphqlOperation(subscriptions.onCreateClient)}
        onSubscriptionMsg={(prev, { onCreateClient }) => {
          console.log (">>", onCreateClient, prev );
          prev.listClients.items.push(onCreateClient)
          return prev; 
      }}>
                {({ data: { listClients }, loading, error }) => {
                    if (error) return (<h3>Error</h3>);

                    if (loading || !listClients) return (
                      <div className="block block-strong text-align-center">
                        <div className="preloader color-multi"></div>
                      </div>
                    );
                    return (
                      <ClientsView clients={listClients.items} /> 
                    )
                }}
            </Connect>
       
    <BlockTitle>Add New Client</BlockTitle>
    <List form>
      <ListInput
        label="Client Name"
        type="text"
        placeholder="The company name"
        onChange={(ev) => { this.handleChange('clientname', ev)}}
      />
      <ListInput
        label="Client ID"
        type="text"
        placeholder="Springfield"
        onChange={(ev) => { this.handleChange('clientid', ev)}}
      />
      <ListInput
        label="Website"
        type="text"
        placeholder="http://"
        onChange={(ev) => { this.handleChange('clientwebsite', ev)}}
      />
      <ListInput
        label="Phone"
        type="text"
        placeholder="Main telephone number (office)"
        onChange={(ev) => { this.handleChange('clientphone', ev)}}
      />
        <ListButton onClick={this.submit.bind(this)}>Create</ListButton>
      </List>
  </Page>
)}}

export default SettingsClients
