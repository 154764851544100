import React from 'react';
import { Page, NavTitle, ListInput, ListButton, Popup, Navbar, Fab, Icon, FabButtons, FabButton, Tab, BlockTitle, Tabs, Link, Toolbar, NavRight,List,ListItem } from 'framework7-react';
import Amplify, { API, graphqlOperation, Logger, Storage }  from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from '../../graphql/queries';
import * as subscriptions from '../../graphql/subscriptions';
import * as mutations from '../../graphql/mutations';
import uuid from 'uuid';
import awsconfig from '../../aws-exports';
import Resizer from 'react-image-file-resizer';
//import FileUpload from "./directives/FileUpload";

Amplify.configure(awsconfig);
//import { ConsoleLogger } from '@aws-amplify/core';
//import ReactJson from 'react-json-view'
//import uuid from "uuid";



const logger = new Logger('log', 'INFO');
logger.info("Carnegis app started")
class App extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      pathToAssets:'https://media.carnegisapp.com/',
      newProjectPopupOpened: false,
      newClientPopupOpened:false,
      createNewProject:{
        projectname:"",
        projectClientId:"",
        projectimage:"",
        structure:""
      },
      createNewClient:{
        clientname:"",
        clientphone:"",
        clientwebsite:""
      },
      clients:[
      ],
      newProjectStructure:{}
    }
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(stateObj, name, ev) {
    this.setState(Object.assign(this.state[stateObj],{[name]:ev.target.value}));
    delete this.state[name];
    //console.log(this.state, this.state[name])
  }

  

  async createNewProject(){
    //console.log("Create New Project", this.state)
    try{
      //console.log("Creating new project", this.state.createNewProject)
      const newProject = await API.graphql(
        graphqlOperation(
          mutations.createProject, {
            input: this.state.createNewProject
          }
        )
      )
      //console.log("Created new project",newProject)
      this.setInitializeProject(newProject.data.createProject);
    }catch(err){
      console.log(err);
    }
  }

  async setInitializeProject(project){
    let self=this;
    self.$f7.dialog.preloader("Creating Resources");
    let theProjectResources=JSON.parse(project.structure);
    console.log("Initializing Project", theProjectResources, project);
    for (var i=0; i<theProjectResources.resources.sections.length; i++){
      //console.log(theProjectResources.resources.sections[i])
      //let theSectionName=theProjectResources.resources.sections[i].name;
      if (theProjectResources.resources.sections[i].resources){
        for (var j=0; j<theProjectResources.resources.sections[i].resources.length; j++){
          //console.log("Resource",theSectionName, theProjectResources.resources.sections[i].resources[j])
          let theResource=theProjectResources.resources.sections[i].resources[j];
          let theInsertStruct={
            resourcename:theResource.label,
            resourcetype:theResource.type,
            resourcesProjectId:project.id,
            order:theProjectResources.resources.sections[i].resources[j].order,
            sectionname:theProjectResources.resources.sections[i].name,
            sectionorder:theProjectResources.resources.sections[i].order
          }
          try{
            const newProject = await API.graphql(
              graphqlOperation(
                mutations.createResources, {
                  input: theInsertStruct
                }
              )) 
              console.log("New Project",newProject)
          }catch(err)
          {
            console.log(err)
          }
        }
      }  
    }
    this.getClients();
    self.$f7.dialog.close();
  }

  async createNewClient(){
    //console.log("Create new client", this.state.createNewClient)

    try{
      const newClient = await API.graphql(
        graphqlOperation(
          mutations.createClient, {
            input: this.state.createNewClient
          }
        )
      )
      console.log(newClient)
    }catch(err){
      console.log(err)
    }
  }

  async onUploadFileResize(e){
    this.$f7.preloader.show();
    let sizes=[50,100,150,250,500];
    console.log("Sizes", sizes)
    this.setState({newProjectImage:uuid.v4() + '.jpg'});
    let config={
        compression:80,
    }
    console.timeLog(config)
    let theFile=e.target.files[0]
    //this.$f7.dialog.progress('Uploading');
    console.log(theFile, this.state)
    
    Resizer.imageFileResizer(theFile,500,'*','JPG',100,0,
      blob=>{
        let self=this
        Storage.put(this.state.newProjectImage, blob, {
          contentType: theFile.type
        }).then(result => {// finished creating thumbnails
            console.log("Uploaded",result);
            this.setState(Object.assign(this.state['createNewProject'],{projectimage:result.key}));
            console.log(this.state)
            self.$f7.preloader.hide();
        })
      }, 'blob')

  }

  async getClients(){
    //console.log("Get Client")
    try{
      const result = await API.graphql(graphqlOperation(
        `query {
          listClients {
            items {
              id
              clientname 
              projects {
                items{
                  projectname
                  projectimage
                  id
                }
                nextToken
              }
            }
            nextToken
          }
        }`
      ))
      this.setState({clients:result.data.listClients.items})
      //console.log(this.state)
    }catch(err){
      console.log("Error loading clients",err)
    }
  }

  async getNewProject(){
    try{
      //console.log('Getting table Project')
      const result = await API.graphql(graphqlOperation(
        `query {
          getProject(id:"` +  0 +`") {
            id
            structure
          }
        }`))
      this.setState({ newProjectStructure: result.data.getProject });
      //this.state.newProjectStructure.structure=JSON.parse(this.state.newProjectStructure.structure)
      this.setState({createNewProject:{...this.state.createNewProject, structure:this.state.newProjectStructure.structure}})
      //this.state.createNewProject.structure=this.state.newProjectStructure.structure.resources;
      //console.log(this.state, JSON.parse(this.state.newProjectStructure.structure));

      } catch (err) {
        console.log('Error loading Project', err)
      }
    }

    triggerFileInput(){
      console.log("File upload")
    }

   componentWillMount(){
    this.getClients();
    this.getNewProject();
    this.newClientSubscription = API.graphql(
        graphqlOperation(subscriptions.onCreateClient)
    ).subscribe({
        next: (client) => (
          this.getClients()
        )
    });
    this.newProjectSubscription = API.graphql(
      graphqlOperation(subscriptions.onCreateProject)
  ).subscribe({
      next: (project) => 
        this.getClients()
  });
    //console.log(this.state)
  }

  clickedOnImageUpload(e){
    this.fileInput.click();
  }

  componentWillUnmount(){
    this.newClientSubscription.unsubscribe();
    this.newProjectSubscription.unsubscribe();
  }

  render() {
    const DropdownWithOptions = ({ clients }) => (
   
    <List >
      <ListInput label="Client" type="select" placeholder="Select a client" 
        value={this.state.createNewProject.projectClientId} 
        onChange={(ev) => { this.handleChange('createNewProject', 'projectClientId', ev)}}> 
          <Icon icon="demo-list-icon" slot="media"/>
          <option value="">Select a Client</option>                 
            {clients.map(client => 
            <option key={client.id} value={client.id}>
              {client.clientname}
              </option>
            )}
      </ListInput>
    </List>
    )

    return (
      <Page pageContent={false}>
        <Navbar borderTheme="red" style={{'backgroundColor':'red'}}>
          <NavTitle>
            <img src="img/logo.png" height="42" alt="Carnegis Logo"></img>
          </NavTitle>
          <NavRight>
            <Link iconIos="f7:bars" iconAurora="f7:bars" iconMd="material:bars" panelOpen="right" onClick={() => this.setState({isBottom: !this.state.isBottom})}></Link>
          </NavRight>
        </Navbar>
        <Toolbar tabbar labels position='bottom' hidden={true}>
          <Link tabLink="#tab-1" tabLinkActive text="Projects" iconIos="f7:business" iconAurora="f7:business" iconMd="f7:business"></Link>
          <Link tabLink="#tab-2" text="Messages" iconIos="f7:email" iconAurora="f7:email" iconMd="f7:email"></Link>
          <Link tabLink="#tab-4" text="Settings" iconIos="f7:gear" iconAurora="f7:gear" iconMd="f7:gear"></Link>
        </Toolbar>
        <Fab position="right-bottom" slot="fixed">
          <Icon ios="f7:add" aurora="f7:add" md="material:add"></Icon>
          <Icon ios="f7:close" aurora="f7:close" md="material:close"></Icon>
          <FabButtons position="top">
            <FabButton label="New Project" 
              onClick={() => this.setState({ newProjectPopupOpened : true })} fabClose={true}>
            
                <Icon ios="f7:briefcase" aurora="f7:briefcase" md="f7:briefcase"></Icon>
             
            </FabButton>
            <FabButton label="New Client" onClick={() => this.setState({ newClientPopupOpened : true })} fabClose={true}>
            <Icon ios="f7:business" aurora="f7:business" md="f7:business"></Icon>
            </FabButton>
          </FabButtons>
        </Fab>
        <Tabs>
          <Tab id="tab-1" className="page-content" tabActive>
          
            {this.state.clients.map((client) => 
            <div key={client.id}>
              <BlockTitle>{client.clientname}</BlockTitle>
              {client.projects.items ? 
                    client.projects.items.map((project) => 
                      <Link key={project.id} href={"/home/project/" + project.id + "/"}  style={{
                        backgroundImage:'url(' + this.state.pathToAssets  + 'public/' + project.projectimage + '), linear-gradient(to bottom, #000000, #FFFFFF)',
                        backgroundPosition:'center',
                        height:100,
                        width:'100%',
                        backgroundBlendMode:'multiply'
                      }}>
                        <div style={{
                          backgroundBlendMode:'normal',
                          color:'white',
                          width:'100%',
                          height:'100%',
                          verticalAlign:'bottom',
                          opacity:'1',
                          fontSize:'3vh',
                          padding:'10px',
                          textAlign:'center'
                          }}>
                          
                              {project.projectname}<br></br>
                             
                          
                          </div>
                          </Link>
                      ): null }
                    
              {/* 
              <ListItem accordionItem key={client.id} title={client.clientname}>
                <AccordionContent>
                  {client.projects.items ? 
                    client.projects.items.map((project) => 
                    
                    
                        <Link href={"/home/project/" + project.id + "/"}  style={{
                          backgroundImage:'url(https://carnegis1-carnegis.s3-us-west-2.amazonaws.com/public/' + project.projectimage + '), linear-gradient(to bottom, #000000, #FFFFFF)',
                          backgroundPosition:'center',
                          height:100,
                          width:'100%',
                          backgroundBlendMode:'multiply'
                        }}>
                          <div style={{
                            backgroundBlendMode:'normal',
                            color:'white',
                            width:'100%',
                            height:'100%',
                            verticalAlign:'bottom',
                            opacity:'1',
                            fontSize:'3vh',
                            padding:'10px',
                            textAlign:'center'
                            }}>
                            
                                {project.projectname}
                            
                            </div>
                        

                        
                      </Link>

                      
                    )
                  : null }
                </AccordionContent>
              </ListItem>
              */}
            </div>
           )}
         
       {/* 
          <BlockTitle>Morgan</BlockTitle>
          <List mediaList>
            <ListItem
              link="/project/"
              title="Core"
              after="In Progress"
              subtitle="Morgan"
              text="Multi Family Property"
            >
              <img slot="media" src="img/thecore_01.jpg" width="80" alt=""/>
            </ListItem>
            <ListItem
              link="#"
              title="Pearl Washington"
              after="Complete"
              subtitle="Morgan"
              text="Multi Family Property"
            >
              <img slot="media" src="img/pearl_01.jpg" width="80" alt=""/>
            </ListItem>
            <ListItem
              link="#"
              title="36 Sixty"
              after="Complete"
              subtitle="Morgan"
              text="Multi Family Property"
            >
              <img slot="media" src="img/3660_01.jpg" width="80" alt=""/>
            </ListItem>
          </List>
          */}
          </Tab>
          <Tab id="tab-2" className="page-content">
          <BlockTitle>Mail App</BlockTitle>
            <List mediaList>
              <ListItem
                link="#"
                title="Facebook"
                after="17:14"
                subtitle="New messages from John Doe"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
              ></ListItem>
              <ListItem
                link="#"
                title="John Doe (via Twitter)"
                after="17:11"
                subtitle="John Doe (@_johndoe) mentioned you on Twitter!"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
              ></ListItem>
              <ListItem
                link="#"
                title="Facebook"
                after="16:48"
                subtitle="New messages from John Doe"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
              ></ListItem>
              <ListItem
                link="#"
                title="John Doe (via Twitter)"
                after="15:32"
                subtitle="John Doe (@_johndoe) mentioned you on Twitter!"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
              ></ListItem>
            </List>
          </Tab>
         {/*  <Tab id="tab-3" className="page-content">
          <List mediaList>
            <ListItem
              link="#"
              title="Yellow Submarine"
              after="$15"
              subtitle="Beatles"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
            >
              <img slot="media" src="https://cdn.framework7.io/placeholder/people-160x160-1.jpg" width="80" alt="-"/>
            </ListItem>
            <ListItem
              link="#"
              title="Don't Stop Me Now"
              after="$22"
              subtitle="Queen"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
            >
              <img slot="media" src="https://cdn.framework7.io/placeholder/people-160x160-2.jpg" width="80" alt="-"/>
            </ListItem>
            <ListItem
              link="#"
              title="Billie Jean"
              after="$16"
              subtitle="Michael Jackson"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus."
            >
              <img slot="media" src="https://cdn.framework7.io/placeholder/people-160x160-3.jpg" width="80" alt="-"/>
            </ListItem>
          </List>
          </Tab>*/}
          <Tab id="tab-4" className="page-content">
            <List>
              <ListItem link="/settings/clients/">Clients</ListItem>
            </List>
          </Tab>
        </Tabs>
        <Popup className="new-client-popup" opened={this.state.newClientPopupOpened} onPopupClosed={() => this.setState({newClientPopupOpened : false})}>
          <Page>
            <Navbar title="New Client">
              <NavRight>
                <Link popupClose>Close</Link>
              </NavRight>
            </Navbar>
            <List noHairlinesMd>
              <ListInput
                label="Client Name"
                type="text"
                placeholder="Name of the client"
                clearButton
                value={this.state.createNewClient.clientname} 
                onChange={(ev) => { this.handleChange('createNewClient', 'clientname', ev)}}
              ></ListInput>

              <ListInput 
                label="Phone" 
                type="text" 
                placeholder="Company phone number" 
                clearButton
                value={this.state.createNewClient.clientphone} 
                onChange={(ev) => { this.handleChange('createNewClient', 'clientphone', ev)}}
                />

                <ListInput 
                  label="Website" 
                  type="text" 
                  placeholder="http://" 
                  clearButton 
                  value={this.state.createNewClient.clientwebsite} 
                  onChange={(ev) => { this.handleChange('createNewClient', 'clientwebsite', ev)}}  
                  />
              <ListButton title="Create Client" 
              onClick={this.createNewClient.bind(this)} popupClose
              />
              </List>
          </Page>
        </Popup>
        <Popup className="new-project-popup" opened={this.state.newProjectPopupOpened} onPopupClosed={() => this.setState({newProjectPopupOpened : false})}>
          <Page>
            <Navbar title="New Project">
              <NavRight>
                <Link popupClose>Close</Link>
              </NavRight>
            </Navbar>
               
                  <Connect query={graphqlOperation(queries.listClients)}>
                      {({ data: { listClients }, loading, error }) => {
                          if (error) return (<h3>Error</h3>);
                          if (loading || !listClients) return (
                            <div className="block block-strong text-align-center">
                              <div className="preloader color-multi"></div>
                            </div>
                          );
                          return (
                            <DropdownWithOptions clients={listClients.items}></DropdownWithOptions>
                          );
                          
                      }}
                  </Connect>
            
              
              {this.state.createNewProject.projectClientId ? 
              <div>
                <List noHairlinesMd mediaList>   
                  <ListInput 
                    label="Project Name" 
                    type="text" 
                    placeholder="The name of the project" 
                    clearButton 
                    value={this.state.createNewProject.projectname} 
                    onChange={(ev) => { this.handleChange('createNewProject', 'projectname', ev)}}></ListInput>
                  {/*<ListItem>
                    <img src="https://carnegis1-carnegis.s3-us-west-2.amazonaws.com/static/upload.png" width="50%" alt="Upload Image" onClick={this.triggerFileInput(this)}></img>
                  </ListItem>*/}
                </List>
                <List>
                  <ListItem link="#" label="Project Image" title="Project Image" after="Upload JPG" type="file" onClick={(e) => this.clickedOnImageUpload(e)}>
                    <img src={this.state.pathToAssets + "static/upload.png"} slot="media" height="44" alt="Upload" onClick={this.triggerFileInput(this)}></img>
                  </ListItem>
                </List>

                <List>
                  <input  hidden
                          ref={fileInput => this.fileInput = fileInput}
                          type="file"
                          accept="image/jpeg"
                          onChange={(e) => this.onUploadFileResize(e)}
                      />
                  <ListButton title="Create Project" onClick={this.createNewProject.bind(this)} popupClose/>
                </List>
              </div>
               : null}
          </Page>
        </Popup>
      </Page>
    )
  }
}

export default App;