import React from "react";
import { SignIn } from "aws-amplify-react";
import {
  Page
} 
from 'framework7-react';
//import { Button } from 'react-bootstrap';
export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    //this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state={
      showButton:false,
      styles:{
        label:{
          'color':'white',
          'fontSize':'10px',
          'padding':'5px',
          'marginBottom':'20px',
          'textAlign':'center'
        },
        message:{
          'color':'white',
          'fontSize':'12px',
          'marginTop':'30px',
          'textAlign':'center'
        },
        input:{
          'borderTopStyle':'none',
            'borderRightStyle':'none',
            'borderLeftStyle':'none',
            'borderBottomStyle':'solid',
            'width':'100%',
            'borderColor':'white',
            'borderWidth':'1px',
            'color':'white',
            'padding':'5px',
            'fontSize':'25px',
            'textAlign':'center'
        },
        button:{
          'color':'red',
          'backgroundColor':'white',
          'borderRadius':'10px',
          'height':'50px',
          'marginTop':'20px',
          'fontSize':'20px'
        }
      }
    }
    this.onInstallBtnClicked = this.onInstallBtnClicked.bind(this);
  }
  componentDidMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.setState({ showButton: true });
      console.log("Ready for PWA", e)
    });
  }

  onInstallBtnClicked(){
    this.setState({ showButton: false });
    this.deferredPrompt.prompt();
    console.log("Clicked on PWA install button")
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }


  showComponent(theme) {
    return (
      
      <Page style={{'backgroundColor':'#c51230', 'fontFamily':'sans-serif' ,'padding':'20px', 'background':'url(img/reddoor_06.jpg) center center'}} className={"color-theme-red"}>
        
        <center>
          <img src="img/logo-sq5.png" height="150" alt="Carnegis Logo" style={{'paddingTop':'50px'}}></img>
        </center>
        <div style={{'padding':'15px'}}>
          
          
          <input 
            type="text" 
            style={this.state.styles.input} 
            placeholder=""
            id="username"
            key="username"
            name="username"
            onChange={this.handleInputChange} 
            label="Username"  
          ></input>
          <label htmlFor="username" style={this.state.styles.label}>USERNAME</label>


          
          <input 
            type="password" 
            style={this.state.styles.input} 
            placeholder=""
            id="password"
            key="password"
            name="password"
            onChange={this.handleInputChange}>
          </input>
          <label htmlFor="password" style={this.state.styles.label}>PASSWORD</label>
       
        
          <button 
            onClick={() => super.signIn()}
            style={this.state.styles.button}>ENTER
          </button>

          

          <div style={this.state.styles.message}>
            <p>This app is for the exclusive use of Carnegis Group clients.
            Contact clients@carnegisgroup.com to request or modify your login credentials.</p> 
          </div>

        {this.state.showButton ? (
          <div onClick={this.onInstallBtnClicked}>
            <center>
              <img src="img/pwa_04.png" height="50" alt="Install PWA Button" style={{'paddingTop':'50px'}}></img>
            </center>
          </div>
        ) : null}


          <div style={this.state.styles.message}><p>Version 0.8</p></div>
        {/* 
          <ListInput outline
            id="username"
            key="username"
            name="username"
            onChange={this.handleInputChange} 
            label="Username" 
            floatingLabel 
            type="text" 
            placeholder="Username" 
            clearButton />
          <ListInput outline 
            id="password"
            key="password"
            name="password"
            onChange={this.handleInputChange}
            label="Password" 
            floatingLabel 
            type="password" 
            placeholder="Password" 
            clearButton />
          <ListButton fill
            onClick={() => super.signIn()}>Login</ListButton>
      */}
    
        </div>
{/* 
      <div className="mx-auto w-full max-w-xs">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="username"
            >
              Username
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              key="username"
              name="username"
              onChange={this.handleInputChange}
              type="text"
              placeholder="Username"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              key="password"
              name="password"
              onChange={this.handleInputChange}
              type="password"
              placeholder="******************"
            />
            <p className="text-grey-dark text-xs">
              Forgot your password?{" "}
              <a
                className="text-indigo cursor-pointer hover:text-indigo-darker"
                onClick={() => super.changeState("forgotPassword")}
              >
                Reset Password
              </a>
            </p>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => super.signIn()}
            >
              Login
            </button>
            <p className="text-grey-dark text-xs">
              No Account?{" "}
              <a
                className="text-indigo cursor-pointer hover:text-indigo-darker"
                onClick={() => super.changeState("signUp")}
              >
                Create account
              </a>
            </p>
          </div>
        </form>
      </div>*/}
      </Page>
      
    )
  }
}