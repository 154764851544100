import React from 'react';
import { Page, Navbar, Link, NavRight} from 'framework7-react';
export default class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            test:true
            }
    }

    render() {
        return (
            <Page>
                <Navbar title={this.$f7route.params.name} backLink="Back">
                    <NavRight>
                        <Link iconIos="f7:reload" iconAurora="f7:reload" iconMd="material:compare_arrows" onClick={() => this.setState({isBottom: !this.state.isBottom})}></Link>
                    </NavRight>
                </Navbar>
                <div>
                    Contract
                </div>
            </Page>
        )
    }
}