// eslint-disable
// this is an auto generated file. This will be overwritten

export const onCreateClient = `subscription OnCreateClient {
  onCreateClient {
    id
    clientname
    clientwebsite
    clientphone
    projects {
      items {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      nextToken
    }
    contacts {
      items {
        id
        contactfirstname
        contactlastname
        contactemail
        contactcellphone
        contactofficephone
        contactaddress1
        contactaddress2
        contactcity
        contactzip
        contactcountry
      }
      nextToken
    }
  }
}
`;
export const onUpdateClient = `subscription OnUpdateClient {
  onUpdateClient {
    id
    clientname
    clientwebsite
    clientphone
    projects {
      items {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      nextToken
    }
    contacts {
      items {
        id
        contactfirstname
        contactlastname
        contactemail
        contactcellphone
        contactofficephone
        contactaddress1
        contactaddress2
        contactcity
        contactzip
        contactcountry
      }
      nextToken
    }
  }
}
`;
export const onDeleteClient = `subscription OnDeleteClient {
  onDeleteClient {
    id
    clientname
    clientwebsite
    clientphone
    projects {
      items {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      nextToken
    }
    contacts {
      items {
        id
        contactfirstname
        contactlastname
        contactemail
        contactcellphone
        contactofficephone
        contactaddress1
        contactaddress2
        contactcity
        contactzip
        contactcountry
      }
      nextToken
    }
  }
}
`;
export const onCreateProject = `subscription OnCreateProject {
  onCreateProject {
    id
    projectname
    projectcity
    projectimage
    order
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
    resources {
      items {
        id
        resourcename
        resourcetype
        sectionname
        sectionorder
        order
        resourcelocation
        structure
        status
      }
      nextToken
    }
    structure
  }
}
`;
export const onUpdateProject = `subscription OnUpdateProject {
  onUpdateProject {
    id
    projectname
    projectcity
    projectimage
    order
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
    resources {
      items {
        id
        resourcename
        resourcetype
        sectionname
        sectionorder
        order
        resourcelocation
        structure
        status
      }
      nextToken
    }
    structure
  }
}
`;
export const onDeleteProject = `subscription OnDeleteProject {
  onDeleteProject {
    id
    projectname
    projectcity
    projectimage
    order
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
    resources {
      items {
        id
        resourcename
        resourcetype
        sectionname
        sectionorder
        order
        resourcelocation
        structure
        status
      }
      nextToken
    }
    structure
  }
}
`;
export const onCreateResources = `subscription OnCreateResources {
  onCreateResources {
    id
    resourcename
    resourcetype
    sectionname
    sectionorder
    order
    resourcelocation
    structure
    status
    project {
      id
      projectname
      projectcity
      projectimage
      order
      client {
        id
        clientname
        clientwebsite
        clientphone
      }
      resources {
        nextToken
      }
      structure
    }
    content {
      items {
        id
        type
        name
        filetype
        fileextension
        size
        label
        structure
        resourceid
      }
      nextToken
    }
  }
}
`;
export const onUpdateResources = `subscription OnUpdateResources {
  onUpdateResources {
    id
    resourcename
    resourcetype
    sectionname
    sectionorder
    order
    resourcelocation
    structure
    status
    project {
      id
      projectname
      projectcity
      projectimage
      order
      client {
        id
        clientname
        clientwebsite
        clientphone
      }
      resources {
        nextToken
      }
      structure
    }
    content {
      items {
        id
        type
        name
        filetype
        fileextension
        size
        label
        structure
        resourceid
      }
      nextToken
    }
  }
}
`;
export const onDeleteResources = `subscription OnDeleteResources {
  onDeleteResources {
    id
    resourcename
    resourcetype
    sectionname
    sectionorder
    order
    resourcelocation
    structure
    status
    project {
      id
      projectname
      projectcity
      projectimage
      order
      client {
        id
        clientname
        clientwebsite
        clientphone
      }
      resources {
        nextToken
      }
      structure
    }
    content {
      items {
        id
        type
        name
        filetype
        fileextension
        size
        label
        structure
        resourceid
      }
      nextToken
    }
  }
}
`;
export const onCreateContent = `subscription OnCreateContent {
  onCreateContent {
    id
    type
    name
    filetype
    fileextension
    size
    label
    structure
    resourceid
    resources {
      id
      resourcename
      resourcetype
      sectionname
      sectionorder
      order
      resourcelocation
      structure
      status
      project {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const onUpdateContent = `subscription OnUpdateContent {
  onUpdateContent {
    id
    type
    name
    filetype
    fileextension
    size
    label
    structure
    resourceid
    resources {
      id
      resourcename
      resourcetype
      sectionname
      sectionorder
      order
      resourcelocation
      structure
      status
      project {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const onDeleteContent = `subscription OnDeleteContent {
  onDeleteContent {
    id
    type
    name
    filetype
    fileextension
    size
    label
    structure
    resourceid
    resources {
      id
      resourcename
      resourcetype
      sectionname
      sectionorder
      order
      resourcelocation
      structure
      status
      project {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const onCreateContacts = `subscription OnCreateContacts {
  onCreateContacts {
    id
    contactfirstname
    contactlastname
    contactemail
    contactcellphone
    contactofficephone
    contactaddress1
    contactaddress2
    contactcity
    contactzip
    contactcountry
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
}
`;
export const onUpdateContacts = `subscription OnUpdateContacts {
  onUpdateContacts {
    id
    contactfirstname
    contactlastname
    contactemail
    contactcellphone
    contactofficephone
    contactaddress1
    contactaddress2
    contactcity
    contactzip
    contactcountry
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
}
`;
export const onDeleteContacts = `subscription OnDeleteContacts {
  onDeleteContacts {
    id
    contactfirstname
    contactlastname
    contactemail
    contactcellphone
    contactofficephone
    contactaddress1
    contactaddress2
    contactcity
    contactzip
    contactcountry
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
}
`;
