import Home from './components/pages/Home';
import Project from './components/pages/Project';
import Projects from './components/pages/Projects';
import Form from './components/pages/Form';
import Billing from './components/pages/Billing';
import SettingsClients from './components/pages/SettingsClients';
import SettingsClientsModify from './components/pages/SettingsClientsModify';
import DynamicRoutePage from './components/pages/DynamicRoutePage';
import NotFoundPage from './components/pages/NotFoundPage';
import PanelLeftPage from './components/pages/PanelLeftPage';
import PanelRightPage from './components/pages/PanelRightPage';
import ContentDocuments from './components/pages/content-documents';
import ContentContract from './components/pages/content-contract';
import ContentBilling from './components/pages/content-billing';
import ContentApproval from './components/pages/content-approval';

export default [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/panel-left/',
    component: PanelLeftPage,
  },
  {
    path: '/panel-right/',
    component: PanelRightPage,
  },
  {
    path: '/home/',
    component: Home,
  },
  {
    path: '/home/project/:id',
    component: Projects,
  },
  {
    path: '/home/project/content/documents/:id/:name/',
    component: ContentDocuments,
  },
  {
    path: '/home/project/content/contract/:id/:name/',
    component: ContentContract,
  },
  {
    path: '/home/project/content/billing/:id/:name/',
    component: ContentBilling,
  },
  {
    path: '/home/project/content/approval/:id/:name/',
    component: ContentApproval,
  },
  {
    path: '/project/',
    component: Project,
  },
  {
    path: '/project/form/',
    component: Form,
  },
  {
    path: '/project/billing/',
    component: Billing,
  },
  {
    path: '/settings/clients/',
    component: SettingsClients,
  },
  {
    path: '/settings/clients/modify/:id/',
    component: SettingsClientsModify,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];
