import React from 'react';
import { Page, Navbar, List, ListItem, Fab, Icon, FabButtons, FabButton, Link, NavRight, ListGroup} from 'framework7-react';
//import { Document } from 'react-pdf/dist/entry.webpack';
//import { pdfjs } from 'react-pdf';
//import ReactS3 from 'react-s3';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import Resizer from 'react-image-file-resizer';
import _ from 'lodash';

//import ReactJson from 'react-json-view'
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default class extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            pathToAssets:'https://media.carnegisapp.com/',
            listContents:[],
            totalFiles:0,
            currentFile:0,
            currentThumbnail:0,
            createContent:{
                name:'',
                contentResourcesId:this.$f7route.params.id,
                type:'',
                size:0
            },
            newFileResult:{}
        }
    }
    async getContent(e){
        //console.log('Getting table Content', this.props.f7route.params.id)
        try{
            const result = await API.graphql(graphqlOperation(
                `query {
                    listContents(limit:1000, filter:{resourceid: {eq:"` + this.props.f7route.params.id + `"}}){
                       items{
                           id
                           type
                           name
                           filetype
                           fileextension
                           size
                           label
                           structure
                           resourceid
                           resources{
                               id
                           }
                       }
                    }
                }`))
            this.setState({ listContents: result.data.listContents.items });
            //console.log(this.state)
            } catch (err) {
                console.log('Error loading Content', err)
            }
        }

    componentWillMount(){
        this.getContent(this);
    }
    


    async onUploadFileResize(e){//Handle uploads with resized thumbnails
        let sizes=[50,100,150,250,500];
        let config={
            compression:80,
        }
        this.$f7.dialog.progress('Uploading');
        this.setState({totalFiles:e.target.files.length})
        for (var i=0; i<e.target.files.length; i++){ // For each file uploaded
            let theImage=e.target.files[i];
            //console.log(theImage)
            API.graphql(
                graphqlOperation(// graphql
                  mutations.createContent, {
                    input: {
                        name:theImage.name,
                        size:theImage.size,
                        filetype:theImage.type,
                        type:'file',
                        label:_.startCase(_.toLower(theImage.name.split('.')[0].replace('_', ' ', ))),
                        fileextension:theImage.name.split('.')[1],
                        resourceid:this.$f7route.params.id,
                        contentResourcesId:this.$f7route.params.id
                    }
                  }
                )
            ).then(result=>{// created database entry
                //console.log("Created Database", result.data.createContent)
                Storage.put(result.data.createContent.id + '.' + theImage.name.split('.')[1], theImage, {// Upload original
                    contentType: theImage.type
                }).then(result=>{// Finished uploading original
                    //let self=this;
                    if (theImage.type.split('/')[0] === 'image'){ // Create thumbnails for images
                        for (var j=0; j< sizes.length; j++){// Loop through sizes
                            let theFileNameComplete=result.key.split('.')
                            let theFilename=theFileNameComplete[0] + '-' + sizes[j] + '.' + theFileNameComplete[1];
                            Resizer.imageFileResizer(theImage,sizes[j],sizes[j],theImage.name.split('.')[1],config.compression,0,
                                blob => {// Resize Image
                                    Storage.put(theFilename, blob, {
                                        contentType: theImage.type
                                    }).then(result => {// Uploaded thumbnail
                                        this.setState({currentThumbnail:this.state.currentThumbnail + 1})
                                        //console.log("Finished Thumbnail", this.state.currentThumbnail, sizes.length)
                                        if (this.state.currentThumbnail === sizes.length){// Finished all the thumbnails
                                            //console.log("Finished Thumbnails")
                                            this.setState({currentThumbnail:0})// Reset the thumbnail count
                                            this.setState({currentFile:this.state.currentFile + 1})
                                            //console.log("Finished File", this.state.currentFile, this.state.totalFiles)
                                            if (this.state.currentFile === this.state.totalFiles){// Finished uploading all the files
                                                this.$f7.dialog.close();
                                                this.setState({currentFile:0});
                                                this.getContent(this);
                                            } 
                                        }
                                    })
                                },
                                'blob'
                            );
                            
                        }
                    } else{ // Not an image file
                        console.log("result", result)
                        Storage.put(result.key, theImage, {// Upload original
                            contentType: theImage.type
                        }).then(result => {
                            this.setState({currentFile:this.state.currentFile + 1})
                            if (this.state.currentFile === this.state.totalFiles){// Finished uploading all the files
                                this.$f7.dialog.close();
                                this.setState({currentFile:0})
                                this.getContent(this);
                            }
                        })
                         
                    }                   
                })
            }).catch(
                err => console.log("Error in Graphql", err)
            )
        }
    }
        /*
      async onUploadFile(e) {
        const file = e.target.files[0];
        const self = this;
        
        //this.setState(createContent.name, e.target.files[0].name)
        this.setState(Object.assign(this.state['createContent'],{['name']:e.target.files[0].name}));
        this.setState(Object.assign(this.state['createContent'],{['type']:e.target.files[0].type}));
        this.setState(Object.assign(this.state['createContent'],{['size']:e.target.files[0].size}));
        this.setState(Object.assign(this.state['createContent'],{['label']:e.target.files[0].name.replace(/_/g, "")}));

        //this.setState({uploadProgress : 0});
        //console.log('file',e.target.files[0], this.state)

        try{
            const newContent = API.graphql(
                graphqlOperation(
                  mutations.createContent, {
                    input: this.state.createContent
                  }
                )
              ).then(function(result){
                self.setState({newFileResult: result.data.createContent})
                console.log("Created", self.state.newFileResult.id);
                Storage.put(self.state.newFileResult.id, file, {
                    progressCallback(progress) {
                        self.setState({uploadProgress : progress.loaded/progress.total});
                        //console.log(`Uploaded`, this.state.uploadProgress, progress.loaded/progress.total);
                  },
                })
                .then (result => self.$f7.dialog.close())
                .catch(
                    err => self.$f7.dialog.close()
                    
                );
              })
              
        }catch(err){
            console.log("Error",err)
        }
        //self.$f7.preloader.show(this.state.uploadProgress + '%');
        self.$f7.dialog.progress('Uploading');
        //console.log("New Content", this.state.newFileResult)
        
      }
    */

    

      fabClick(e){
          //console.log("Clicked on fab");
          //console.log("Resource ID",this.$f7route.params.id)
          this.fileInput.click()
      }
      
    render() {
        /*const config = {
            bucketName: 'myBucket',
            dirName: 'school-documents',
            region: 'eu-west-1',
            accessKeyId: 'ANEIFNENI4324N2NIEXAMPLE',
            secretAccessKey: 'cms21uMxçduyUxYjeg20+DEkgDxe6veFosBT7eUgEXAMPLE',
        }
        
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            }).then(user => console.log(user))
            .catch(err => console.log(err));
        
        Auth.currentSession()
            .then(data => console.log(data))
            .catch(err => console.log(err));
         
        const filename = 'hello-world.pdf'*/
        return (
            <Page>
                <Navbar title={this.$f7route.params.name} backLink="Back" large>
                    <NavRight>
                        <Link iconIos="f7:reload" iconAurora="f7:reload" iconMd="material:compare_arrows" onClick={() => this.setState({isBottom: !this.state.isBottom})}></Link>
                    </NavRight>
                </Navbar>
                
                    <Fab position="right-bottom" slot="fixed" >
                    <Icon ios="f7:add" aurora="f7:add" md="material:add"></Icon>
                    <Icon ios="f7:close" aurora="f7:close" md="material:close"></Icon>
                        <FabButtons position="center">
                            <FabButton ios="f7:add" onClick={(e) => this.fabClick(e)} label="Upload">
                                <Icon ios="f7:cloud_upload" aurora="f7:add" md="material:add"></Icon>
                            </FabButton>
                        </FabButtons>
                    </Fab>
                    <List mediaList>

                        {this.state.listContents.map((content) =>
                            <ListGroup key={content.id}>
                            {(() => {
                                
                                switch(content.filetype.split('/')[1]) {
                                    case "jpeg": return(
                                        <ListItem target="_blank" external href={this.state.pathToAssets + 'public/' + content.id + '.jpg'} key={content.id} subtitle={content.id} title={content.label} after={content.filetype} internallink={"/home/project/content/documents/view/" + content.id + "/"}>
                                            <img slot="media" src={this.state.pathToAssets + 'public/' + content.id + '-50.jpg'} width="44" alt={content.label}/>
                                        </ListItem>
                                    )
                                    
                                    case "pdf": return(
                                        <ListItem target="_blank" external href={this.state.pathToAssets + 'public/' + content.id + '.pdf'} key={content.id} subtitle={content.id} title={content.label} after={content.filetype} internallink={"/home/project/content/documents/view/" + content.id + "/"}>
                                            <img slot="media" src={this.state.pathToAssets + 'static/acrobat.png'} height="44" alt={content.label}/>
                                        </ListItem>
                                    )
                                    default: return null;
                                }
                            })()}
                            </ListGroup>
                                
                            
                      
                        )}
                    </List>
                    {/* <ReactJson src={this.state.listContents}></ReactJson> */}

                
                <input hidden
                    multiple
                        ref={fileInput => this.fileInput = fileInput}
                        type="file"
                        accept="image/jpeg,image/gif,image/png,application/pdf"
                        onChange={(e) => this.onUploadFileResize(e)}
                    />

            </Page>
        )
    }
}