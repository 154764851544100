// eslint-disable
// this is an auto generated file. This will be overwritten

export const getClient = `query GetClient($id: ID!) {
  getClient(id: $id) {
    id
    clientname
    clientwebsite
    clientphone
    projects {
      items {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      nextToken
    }
    contacts {
      items {
        id
        contactfirstname
        contactlastname
        contactemail
        contactcellphone
        contactofficephone
        contactaddress1
        contactaddress2
        contactcity
        contactzip
        contactcountry
      }
      nextToken
    }
  }
}
`;
export const listClients = `query ListClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getProject = `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    projectname
    projectcity
    projectimage
    order
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
    resources {
      items {
        id
        resourcename
        resourcetype
        sectionname
        sectionorder
        order
        resourcelocation
        structure
        status
      }
      nextToken
    }
    structure
  }
}
`;
export const listProjects = `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectname
      projectcity
      projectimage
      order
      client {
        id
        clientname
        clientwebsite
        clientphone
      }
      resources {
        nextToken
      }
      structure
    }
    nextToken
  }
}
`;
export const getResources = `query GetResources($id: ID!) {
  getResources(id: $id) {
    id
    resourcename
    resourcetype
    sectionname
    sectionorder
    order
    resourcelocation
    structure
    status
    project {
      id
      projectname
      projectcity
      projectimage
      order
      client {
        id
        clientname
        clientwebsite
        clientphone
      }
      resources {
        nextToken
      }
      structure
    }
    content {
      items {
        id
        type
        name
        filetype
        fileextension
        size
        label
        structure
        resourceid
      }
      nextToken
    }
  }
}
`;
export const listResourcess = `query ListResourcess(
  $filter: ModelResourcesFilterInput
  $limit: Int
  $nextToken: String
) {
  listResourcess(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      resourcename
      resourcetype
      sectionname
      sectionorder
      order
      resourcelocation
      structure
      status
      project {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      content {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getContent = `query GetContent($id: ID!) {
  getContent(id: $id) {
    id
    type
    name
    filetype
    fileextension
    size
    label
    structure
    resourceid
    resources {
      id
      resourcename
      resourcetype
      sectionname
      sectionorder
      order
      resourcelocation
      structure
      status
      project {
        id
        projectname
        projectcity
        projectimage
        order
        structure
      }
      content {
        nextToken
      }
    }
  }
}
`;
export const listContents = `query ListContents(
  $filter: ModelContentFilterInput
  $limit: Int
  $nextToken: String
) {
  listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      name
      filetype
      fileextension
      size
      label
      structure
      resourceid
      resources {
        id
        resourcename
        resourcetype
        sectionname
        sectionorder
        order
        resourcelocation
        structure
        status
      }
    }
    nextToken
  }
}
`;
export const getContacts = `query GetContacts($id: ID!) {
  getContacts(id: $id) {
    id
    contactfirstname
    contactlastname
    contactemail
    contactcellphone
    contactofficephone
    contactaddress1
    contactaddress2
    contactcity
    contactzip
    contactcountry
    client {
      id
      clientname
      clientwebsite
      clientphone
      projects {
        nextToken
      }
      contacts {
        nextToken
      }
    }
  }
}
`;
export const listContactss = `query ListContactss(
  $filter: ModelContactsFilterInput
  $limit: Int
  $nextToken: String
) {
  listContactss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contactfirstname
      contactlastname
      contactemail
      contactcellphone
      contactofficephone
      contactaddress1
      contactaddress2
      contactcity
      contactzip
      contactcountry
      client {
        id
        clientname
        clientwebsite
        clientphone
      }
    }
    nextToken
  }
}
`;
